:root {
    --color-scheme-light-correct: #489074;
    --color-scheme-light-wrong: #d4a86a;
    --color-scheme-light-invalid: #c1607a;
    --color-scheme-light-none: #ffffff;
    --color-scheme-light-text: #000000;
    --color-scheme-light-background: #ffffff;
    --color-scheme-light-border: #bbbbbb;
    --color-scheme-light-disabled: #dddddd;

    --color-scheme-dark-correct: #0B5438;
    --color-scheme-dark-wrong: #7A4E10;
    --color-scheme-dark-invalid: #700F29;
    --color-scheme-dark-none: #333333;
    --color-scheme-dark-text: #ffffff;
    --color-scheme-dark-background: #333333;
    --color-scheme-dark-border: #000000;
    --color-scheme-dark-disabled: #555555;

    --size-header: 40px;
    --size-footer: calc(4.5 * var(--size-header));
}

.color-scheme-initial {
    --color-correct: var(--color-scheme-light-correct);
    --color-wrong: var(--color-scheme-light-wrong);
    --color-invalid: var(--color-scheme-light-invalid);
    --color-none: var(--color-scheme-light-none);
    --color-text: var(--color-scheme-light-text);
    --color-background: var(--color-scheme-light-background);
    --color-border: var(--color-scheme-light-border);
    --color-disabled: var(--color-scheme-light-disabled);
}

.color-scheme-swapped {
    --color-correct: var(--color-scheme-dark-correct);
    --color-wrong: var(--color-scheme-dark-wrong);
    --color-invalid: var(--color-scheme-dark-invalid);
    --color-none: var(--color-scheme-dark-none);
    --color-text: var(--color-scheme-dark-text);
    --color-background: var(--color-scheme-dark-background);
    --color-border: var(--color-scheme-dark-border);
    --color-disabled: var(--color-scheme-dark-disabled);
}

@media (prefers-color-scheme: dark) {
    .color-initial {
        --color-correct: var(--color-scheme-dark-correct);
        --color-wrong: var(--color-scheme-dark-wrong);
        --color-invalid: var(--color-scheme-dark-invalid);
        --color-none: var(--color-scheme-dark-none);
        --color-text: var(--color-scheme-dark-text);
        --color-background: var(--color-scheme-dark-background);
        --color-border: var(--color-scheme-dark-border);
        --color-disabled: var(--color-scheme-dark-disabled);
    }

    .color-swapped {
        --color-correct: var(--color-scheme-light-correct);
        --color-wrong: var(--color-scheme-light-wrong);
        --color-invalid: var(--color-scheme-light-invalid);
        --color-none: var(--color-scheme-light-none);
        --color-text: var(--color-scheme-light-text);
        --color-background: var(--color-scheme-light-background);
        --color-border: var(--color-scheme-light-border);
        --color-disabled: var(--color-scheme-light-disabled);
    }
}

@media (prefers-color-scheme: light) {
    .color-initial {
        --color-correct: var(--color-scheme-light-correct);
        --color-wrong: var(--color-scheme-light-wrong);
        --color-invalid: var(--color-scheme-light-invalid);
        --color-none: var(--color-scheme-light-none);
        --color-text: var(--color-scheme-light-text);
        --color-background: var(--color-scheme-light-background);
        --color-border: var(--color-scheme-light-border);
        --color-disabled: var(--color-scheme-light-disabled);
    }

    .color-swapped {
        --color-correct: var(--color-scheme-dark-correct);
        --color-wrong: var(--color-scheme-dark-wrong);
        --color-invalid: var(--color-scheme-dark-invalid);
        --color-none: var(--color-scheme-dark-none);
        --color-text: var(--color-scheme-dark-text);
        --color-background: var(--color-scheme-dark-background);
        --color-border: var(--color-scheme-dark-border);
        --color-disabled: var(--color-scheme-dark-disabled);
    }
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.none {
    background-color: var(--color-none);
}

.wrong-placement {
    background-color: var(--color-wrong);
}

.invalid {
    background-color: var(--color-invalid);
}

.correct {
    background-color: var(--color-correct);
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;

    .game {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: var(--size-header) auto var(--size-footer);
        grid-template-columns: 100%;
        color: var(--color-text);

        .output {
            display: flex;
            justify-content: center;
            align-items: center;

            &.correct {
                background: linear-gradient(var(--color-background), var(--color-correct));
            }

            &.invalid {
                background: linear-gradient(var(--color-background), var(--color-invalid));
            }

            &.wrong-placement {
                background: linear-gradient(var(--color-background), var(--color-wrong));
            }

            .output-text {
                font-size: calc(0.75 * var(--size-header));
                display: flex;
                justify-content: center;
                align-items: center;

                .output-text-inner {
                    display: inline-block;
                    user-select: none;
                    cursor: default;
                }
            }
        }

        .view {
            overflow-y: scroll;
            width: 100%;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            scrollbar-color: var(--color-disabled) var(--color-border);
            /* width */
            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: var(--color-disabled);
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: var(--color-border);
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: var(--color-border);
            }

            .square {
                border: 1px solid var(--color-border);
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(6n + 1) {
                    background-color: var(--color-none);
                }

                .square-inner {
                    font-size: var(--size-header);
                    display: inline-block;
                }
            }
        }

        .input {
            width: 100%;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr 0.5fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-areas:
                "Q W E R T Y U I O P"
                "A S D F G H J K L Å"
                "Z X C V B N M Æ Ø DEL"
                "DM NEW NEW NEW HINT HINT HINT GUESS GUESS GUESS"
                "CR CR CR CR CR CR CR CR CR CR";

            .btn {
                border: 1px solid var(--color-border);
                color: var(--color-text);

                &.none:disabled {
                    background-color: var(--color-disabled);
                }

                .btn-small-text {
                    font-size: 0.6rem;
                }
            }

            .copyright {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                grid-area: CR;
                background-color: var(--color-none);

                .copyright-text {
                    padding-right: 10px;
                    font-size: 0.7rem;
                    display: inline-block;
                }
            }
        }
    }
}

@media (orientation: landscape) {
    .game {
        max-width: calc(100vh * 0.5);
    }
}
